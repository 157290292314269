"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useParams = void 0;
var react_router_1 = require("react-router");
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
var useParams = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (0, react_router_1.useParams)();
};
exports.useParams = useParams;
