import _ from 'lodash';
import { sharedTranslations } from 'shared-ui/lib/shared-translations';
import LoginSceneTranslation from './scenes/LoginScene.translation';
import { AdminOptionsSceneTranslation } from './scenes/admin-options/AdminOptionsScene.translation';
import { UserEditSceneTranslation } from './scenes/user/UserEditScene.translation';
import { UserIndexSceneTranslation } from './scenes/user/UserIndexScene.translation';
import { VehicleEditSceneTranslation } from './scenes/vehicle/VehicleEditScene.translation';
import { VehicleIndexSceneTranslation } from './scenes/vehicle/VehicleIndexScene.translation';
import { VehicleTransferBusinessSceneTranslation } from './scenes/vehicle/VehicleTransferBusinessScene.translation';
import { VehicleTransferConsumerSceneTranslation } from './scenes/vehicle/VehicleTransferConsumerScene.translation';
import { VoucherCreateSceneTranslation } from './scenes/voucher/VoucherCreateScene.translation';
import { VoucherEditSceneTranslation } from './scenes/voucher/VoucherEditScene.translation';
import { VoucherIndexSceneTranslation } from './scenes/voucher/VoucherIndexScene.translation';
import { CommonTranslation } from './translations/common.translation';
import { SideBarTranslation } from './translations/sideBar.translation';
import { VoucherTranslation } from './translations/voucher.translation';

export default _.merge(
  sharedTranslations,
  VoucherIndexSceneTranslation,
  LoginSceneTranslation,
  VoucherTranslation,
  AdminOptionsSceneTranslation,
  VehicleIndexSceneTranslation,
  VoucherCreateSceneTranslation,
  CommonTranslation,
  SideBarTranslation,
  UserIndexSceneTranslation,
  UserEditSceneTranslation,
  VoucherEditSceneTranslation,
  VehicleEditSceneTranslation,
  VehicleTransferBusinessSceneTranslation,
  VehicleTransferConsumerSceneTranslation
);
