import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { adminOptionsInput } from '../inputs/admin-options.input';

const form: FormInput[] = [adminOptionsInput.archiveUser, adminOptionsInput.stripePriceId];

export const adminOptionsForm = createForm(form);

export type AdminOptionsFormBody = {
  archiveUser: string;
  stripePriceId: string;
};
