export const CommonTranslation = {
  fi: {
    Common: {
      save: 'Tallenna',
      back: 'Takaisin',
      success: 'Luotu onnistuneesti',
      loading: 'Lataa..',
      deleteText: 'Poista',
      saved: 'Tallennettu',
    },
  },
};
