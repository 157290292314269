"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericTable = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var GenericTableHeader_1 = require("./GenericTableHeader");
var GenericTableRow_1 = require("./GenericTableRow");
var GenericTable = function (props) {
    var data = props.data, onSortClick = props.onSortClick, columns = props.columns, thClassNames = props.thClassNames, thActiveClassNames = props.thActiveClassNames, tdClassNames = props.tdClassNames, trClassNames = props.trClassNames, tableClassNames = props.tableClassNames, tHeadClassNames = props.tHeadClassNames, tBodyClassNames = props.tBodyClassNames, sortIconClassNames = props.sortIconClassNames;
    return ((0, jsx_runtime_1.jsxs)("table", { className: tableClassNames, children: [(0, jsx_runtime_1.jsx)("thead", { className: tHeadClassNames, children: (0, jsx_runtime_1.jsx)("tr", { children: columns.map(function (column) { return ((0, jsx_runtime_1.jsx)(GenericTableHeader_1.GenericTableHeader, { thClassNames: thClassNames, thActiveClassNames: thActiveClassNames, sortIconClassNames: sortIconClassNames, column: column, onSortClick: onSortClick }, "".concat(column.field, "-").concat(column.text))); }) }) }), (0, jsx_runtime_1.jsx)("tbody", { className: tBodyClassNames, children: (data || []).map(function (d) { return ((0, jsx_runtime_1.jsx)(GenericTableRow_1.GenericTableRow, { trOnClick: props.trOnClick, trClassNames: trClassNames, tdClassNames: tdClassNames, data: d, columns: columns }, d.id)); }) })] }));
};
exports.GenericTable = GenericTable;
