export const routesConfig = {
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
  },
  VOUCHER: {
    INDEX: '/',
    EDIT: '/voucher/:id',
    CREATE: '/voucher/new',
  },
  USER: {
    INDEX: '/user',
    EDIT: '/user/:id',
  },
  VEHICLE: {
    INDEX: '/vehicle',
    EDIT: '/vehicle/:id',
    TRANSFER_BUSINESS: '/vehicle/:id/transfer-business',
    TRANSFER_CONSUMER: '/vehicle/:id/transfer-consumer',
  },
  ADMIN_OPTIONS: '/admin-options',
};
