export const SideBarTranslation = {
  fi: {
    SideBar: {
      voucher: 'Kupongit',
      users: 'Käyttäjät',
      vehicles: 'Ajoneuvot',
      adminOptions: 'Admin asetukset',
    },
  },
};
