import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card } from 'shared-ui/lib/components/Card';
import { CardTitle } from 'shared-ui/lib/components/CardTitle';
import { AppFormScene } from 'shared-ui/lib/releox-engine/react/app-form-scene/AppFormScene';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { VehicleFormBody, vehicleEditForm } from '../../forms/vehicle.form';
import { useUpdateVehicleMutation, useVehicleEditSceneQuery } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const VehicleEditScene = (): JSX.Element => {
  const { t } = useTranslation('VehicleEditScene');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const context = useGQLContext();

  const query = useVehicleEditSceneQuery({ variables: { id }, context });

  const [updateVehicle, { loading }] = useUpdateVehicleMutation({
    onCompleted: (data) => {
      if (data.aUpdateVehicle) {
        toast.success(t('Common:saved'));
        navigate(routesConfig.VEHICLE.INDEX);
      }
    },
    context,
  });

  const handleSubmit = (body: VehicleFormBody) => {
    updateVehicle({
      variables: {
        id,
        body: {
          isArchived: body.isArchived,
          token: body.token,
        },
      },
    });
  };

  return (
    <>
      <AppFormScene
        type="edit"
        query={query}
        queryDataPath="aVehicle"
        title={t('title')}
        form={vehicleEditForm}
        isSubmitLoading={loading}
        onSubmit={handleSubmit}
        backLink={routesConfig.USER.INDEX}
      />

      <div className="mt-8">
        <div className="mx-auto w-full max-w-3xl sm:px-2">
          <Card>
            <CardTitle>Info</CardTitle>
            <p>Rekisterinumero: {query.data?.aVehicle.registerNumber ?? '-'}</p>
            <p>VIN: {query.data?.aVehicle.VIN ?? '-'}</p>
          </Card>
        </div>
      </div>
      <div className="mt-6 mx-auto w-full max-w-3xl sm:px-2">
        <Card>
          <CardTitle>Siirrä ajoneuvo</CardTitle>
          <Link
            to={routesConfig.VEHICLE.TRANSFER_BUSINESS.replace(':id', id)}
            className="inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          >
            Siirrä yritykselle
          </Link>
          <Link
            to={routesConfig.VEHICLE.TRANSFER_CONSUMER.replace(':id', id)}
            className="inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          >
            Siirrä yksityishenkilölle
          </Link>
        </Card>
      </div>
    </>
  );
};
