import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useSubmitHandler } from 'shared-ui/lib/releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from 'shared-ui/lib/releox-engine/react/app-form-scene/AppFormScene';
import { routesConfig } from '../../config/routes.config';
import { voucherForm } from '../../forms/voucher.form';
import { useCreateVoucherMutation } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const VoucherCreateScene = (): JSX.Element => {
  const { t } = useTranslation('VoucherCreateScene');
  const context = useGQLContext();
  const navigate = useNavigate();

  const [createVoucher, { loading }] = useCreateVoucherMutation({
    context,
    onCompleted: (data) => {
      if (data.createVoucher) {
        toast.success(t('Common:success'));
        navigate(routesConfig.VOUCHER.INDEX);
      }
    },
  });

  const handleSubmit = useSubmitHandler(createVoucher);

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={voucherForm}
      isSubmitLoading={loading}
      backLink={routesConfig.VOUCHER.INDEX}
      onSubmit={handleSubmit}
    />
  );
};
