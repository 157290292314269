"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableLayout = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Card_1 = require("./Card");
var TableLayout = function (_a) {
    var children = _a.children;
    return (0, jsx_runtime_1.jsx)(Card_1.Card, { children: children });
};
exports.TableLayout = TableLayout;
