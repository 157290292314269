export const AdminOptionsSceneTranslation = {
  fi: {
    AdminOptionsScene: {
      title: 'Admin asetukset',
    },
    AdminOptions: {
      stripePriceId: 'Stripe hinta ID',
      archiveUser: 'Arkisto käyttäjä',
    },
  },
};
