"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appIndexSceneConfig = void 0;
var outline_1 = require("@heroicons/react/outline");
var CardTitle_1 = require("../../../components/CardTitle");
var TableLayout_1 = require("../../../components/TableLayout");
exports.appIndexSceneConfig = {
    perPage: 30,
    defaultOrderBy: { createdAt: 'desc' },
    Layout: TableLayout_1.TableLayout,
    Title: CardTitle_1.CardTitle,
    SortAscendingIcon: outline_1.SortAscendingIcon,
    SortDescendingIcon: outline_1.SortDescendingIcon,
    defaultClasses: {
        thActiveClassNames: 'text-tertiary',
        tableClassNames: 'bg-white min-w-full divide-y divide-gray-200 overflow-hidden border-b border-gray-200 rounded-lg',
        tHeadClassNames: 'bg-gray-50',
        thClassNames: 'px-6 py-3 text-left text-xs font-medium text-gray-color uppercase tracking-wider',
        tdClassNames: "px-6 py-4 text-sm font-medium",
        trClassNames: 'even:bg-gray-50',
    },
};
