export const VoucherTranslation = {
  fi: {
    Voucher: {
      name: 'Nimi',
      isActive: 'Aktiivinen',
      useLimit: 'Käyttöraja',
      endDate: 'Päättymispäivä',
      uses: 'Käytetty',
      createdAt: 'Luotu',
    },
  },
};
