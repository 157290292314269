"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var Button_1 = require("./Button");
var DeleteButton = function (_a) {
    var onClick = _a.onClick, loading = _a.loading;
    var t = (0, react_i18next_1.useTranslation)('Common').t;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsx)(Button_1.Button, { type: "button", onClick: onClick, loading: loading, className: "float-left bg-tertiary hover:bg-red-500", children: t('deleteText') }), (0, jsx_runtime_1.jsx)("div", { className: "clear-both" })] }));
};
exports.DeleteButton = DeleteButton;
