import { Link } from 'react-router-dom';
import { TableColumn } from 'shared-ui/lib/releox-engine/react/generic-table/TableColumn';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../config/routes.config';
import { VoucherIndexSceneQuery } from '../generated/graphql';

const name: TableColumn = {
  field: 'name',
  text: 'Voucher:name',
};

const isActive: TableColumn = {
  field: 'isActive',
  text: 'Voucher:isActive',
  formatter: (data) => {
    if (data.isActive) return 'Yes';
    return 'No';
  },
};

const useLimit: TableColumn = {
  field: 'useLimit',
  text: 'Voucher:useLimit',
};

const uses: TableColumn = {
  field: 'uses',
  text: 'Voucher:uses',
};

const endDate: TableColumn = {
  field: 'endDate',
  text: 'Voucher:endDate',
  formatter: (d) => formatDate(d.endDate),
};

const createdAt: TableColumn = {
  field: 'createdAt',
  text: 'Voucher:createdAt',
  formatter: (d) => formatDate(d.createdAt),
};

const linkToVoucher: TableColumn = {
  field: 'id',
  text: 'General:link',
  formatter: (data: VoucherIndexSceneQuery['vouchers']['list'][0]) => (
    <Link
      to={routesConfig.VOUCHER.EDIT.replace(':id', data.id)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const voucherColumn = {
  name,
  isActive,
  useLimit,
  endDate,
  createdAt,
  uses,
  linkToVoucher,
};
