import { Link } from 'react-router-dom';
import { TableColumn } from 'shared-ui/lib/releox-engine/react/generic-table/TableColumn';
import { routesConfig } from '../config/routes.config';
import { VehicleIndexSceneQuery } from '../generated/graphql';

const id: TableColumn = {
  field: 'id',
  text: 'Vehicle:id',
};

const name: TableColumn = {
  field: 'name',
  text: 'Vehicle:name',
  sort: true,
};

const VIN: TableColumn = {
  field: 'VIN',
  text: 'Vehicle:VIN',
  tdClassNames: 'text-gray-color',
  sort: true,
};

const registerNumber: TableColumn = {
  field: 'registerNumber',
  text: 'Vehicle:registerNumber',
  tdClassNames: 'text-gray-color',
};

const mileage: TableColumn = {
  field: 'mileage',
  text: 'Vehicle:mileage',
  tdClassNames: 'text-gray-color',
};

const modelYear: TableColumn = {
  field: 'modelYear',
  text: 'Vehicle:modelYear',
  tdClassNames: 'text-gray-color',
};

const model: TableColumn = {
  field: 'model',
  text: 'Vehicle:model',
  tdClassNames: 'text-gray-color',
};

const brand: TableColumn = {
  field: 'brand',
  text: 'Vehicle:brand',
  tdClassNames: 'text-gray-color',
};

const token: TableColumn = {
  field: 'token',
  text: 'Vehicle:token',
  tdClassNames: 'text-gray-color',
};

const type: TableColumn = {
  field: 'type',
  text: 'Vehicle:type',
  tdClassNames: 'text-gray-color',
};

const isArchived: TableColumn = {
  field: 'isArchived',
  text: 'Vehicle:isArchived',
  tdClassNames: 'text-gray-color',
  formatter: (data: VehicleIndexSceneQuery['aVehicles']['list'][0]) => (
    <p>{data.isArchived ? 'Kyllä' : 'Ei'}</p>
  ),
};

const business: TableColumn = {
  field: 'id',
  text: 'Vehicle:businessName',
  tdClassNames: 'text-gray-color',
  formatter: (data: VehicleIndexSceneQuery['aVehicles']['list'][0]) => (
    <p>{data.business?.name ?? '-'}</p>
  ),
};

const user: TableColumn = {
  field: 'id',
  text: 'Vehicle:userName',
  tdClassNames: 'text-gray-color',
  formatter: (data: VehicleIndexSceneQuery['aVehicles']['list'][0]) => (
    <p>{data.user?.name ?? '-'}</p>
  ),
};

const linkToVehicle: TableColumn = {
  field: 'id',
  text: 'General:link',
  formatter: (data: VehicleIndexSceneQuery['aVehicles']['list'][0]) => (
    <Link
      to={routesConfig.VEHICLE.EDIT.replace(':id', data.id)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const vehicleColumn = {
  name,
  VIN,
  registerNumber,
  isArchived,
  type,
  token,
  linkToVehicle,
  brand,
  id,
  modelYear,
  model,
  mileage,
  business,
  user,
};
