"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObjectChangeWatcher = void 0;
var react_1 = require("react");
var useObjectChangeWatcher = function (watched, onChange) {
    var _a = (0, react_1.useState)(null), cache = _a[0], setCache = _a[1];
    (0, react_1.useEffect)(function () {
        var localCache = JSON.stringify(watched);
        if (localCache !== cache) {
            setCache(localCache);
            onChange(watched);
        }
    }, [watched, cache, onChange]);
};
exports.useObjectChangeWatcher = useObjectChangeWatcher;
