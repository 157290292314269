import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { voucherInput } from '../inputs/voucher.input';

const form: FormInput[] = [
  voucherInput.name,
  voucherInput.isActive,
  voucherInput.useLimit,
  voucherInput.endDate,
];

const editForm: FormInput[] = [voucherInput.isActive, voucherInput.useLimit, voucherInput.endDate];

export const voucherForm = createForm(form);
export const voucherEditForm = createForm(editForm);

export type VoucherFormBody = {
  name: string;
  isActive: boolean;
  useLimit: number;
  endDate: number;
};
