import { useRecoilState } from 'recoil';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { AppFormGenerator } from 'shared-ui/lib/releox-engine/react/app-form-generator/AppFormGenerator';
import { UserFilterFormBody, userFilterForm } from '../forms/user-filter.form';
import { userFilterAtom } from '../store/user-filter.atom';

const Footer = () => {
  return <Button type="submit">Etsi</Button>;
};

export const UserFilter = (): JSX.Element => {
  const [userFilter, setUserFilter] = useRecoilState(userFilterAtom);

  const onSubmit = (data: UserFilterFormBody) => {
    setUserFilter(data);
  };

  return (
    <Card>
      <AppFormGenerator
        wrapperClassNames="space-y-4"
        initialValues={userFilter}
        form={userFilterForm}
        FooterElement={<Footer />}
        onSubmit={onSubmit}
      />
    </Card>
  );
};
