import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ScrollToTop } from 'shared-ui/lib/components/ScrollOnTop';
import { ParseUrl } from 'shared-ui/lib/services/parse-url';
import { HomeRouter } from './HomeRouter';
import { RequireAuth } from './components/RequireAuth';
import { RequireGuest } from './components/RequireGuest';
import { routesConfig } from './config/routes.config';
import { AuthContext } from './contexts/AuthContext';
import { LoginScene } from './scenes/LoginScene';

const App = () => {
  const navigation = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [, setToken] = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (!isMounted) {
      try {
        const token = ParseUrl.parseLocation(location, 'token');
        localStorage.setItem('admin-token', token);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Token not found');
      }
      setIsMounted(true);
    }
  }, [location, setToken, isMounted, navigation]);

  if (!isMounted) return <span />;

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path={routesConfig.AUTH.LOGIN}
          element={
            <RequireGuest>
              <LoginScene />
            </RequireGuest>
          }
        />
        <Route
          path="*"
          element={
            <RequireAuth>
              <HomeRouter />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default App;
