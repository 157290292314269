"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericTableHeader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
var react_i18next_1 = require("react-i18next");
var GenericTableHeader = function (_a) {
    var column = _a.column, onSortClick = _a.onSortClick, thClassNames = _a.thClassNames, thActiveClassNames = _a.thActiveClassNames, sortIconClassNames = _a.sortIconClassNames;
    var t = (0, react_i18next_1.useTranslation)().t;
    var icon;
    if (column.sort && sortIconClassNames && onSortClick) {
        icon = (0, jsx_runtime_1.jsx)(sortIconClassNames.asc, { onClick: function () { return onSortClick(column); } });
        if (column.activeSort === 'desc') {
            icon = (0, jsx_runtime_1.jsx)(sortIconClassNames.desc, { onClick: function () { return onSortClick(column); } });
        }
    }
    return ((0, jsx_runtime_1.jsxs)("th", { className: [thClassNames, column.activeSort ? thActiveClassNames : undefined]
            .filter(function (x) { return x; })
            .join(' '), children: [t(column.text), icon] }, column.field));
};
exports.GenericTableHeader = GenericTableHeader;
