"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSortableTableColumns = void 0;
var immutable_1 = require("immutable");
var getSortableTableColumns = function (columns, orderBy) {
    // Create immutable array
    var localColumns = (0, immutable_1.List)(columns || []);
    // Find current orderBy column
    var columnIndex = localColumns.findIndex(function (c) { return (c === null || c === void 0 ? void 0 : c.field) === Object.keys(orderBy)[0]; });
    // Get current sort order (asc or desc)
    var currentSortOrder = Object.values(orderBy)[0];
    // If orderBy column is found
    if (columnIndex !== -1) {
        var column = localColumns.get(columnIndex);
        // This line is for fixing typescript auto typing undefined type
        if (!column)
            throw new Error('[UseIndex] Column is undefined');
        // Set activeSort field to current sorted column
        localColumns = localColumns.set(columnIndex, __assign(__assign({}, column), { activeSort: currentSortOrder }));
    }
    return localColumns.toArray();
};
exports.getSortableTableColumns = getSortableTableColumns;
