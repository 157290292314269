import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { Button } from 'shared-ui/lib/components/Button';
import { useLoginLazyQuery } from '../generated/graphql';

export const LoginScene = () => {
  const { t } = useTranslation('LoginScene');

  const [requestToken, { loading }] = useLoginLazyQuery({
    onCompleted: (data) => {
      if (data.requestAdminToken.status) {
        toast.success(t('tokenSent'));
      }
    },
  });

  return (
    <AuthLayout>
      <AuthCard>
        <AuthLogoTitle title={t('title')} className="text-primary mb-4" />
        <Button type="button" onClick={requestToken} loading={loading} className="w-full">
          {t('login')}
        </Button>
      </AuthCard>
    </AuthLayout>
  );
};
