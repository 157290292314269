import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import * as Yup from 'yup';

const userId: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'userId',
    label: 'User:userId',
    type: 'text',
  },
};

const businessId: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'businessId',
    label: 'User:businessId',
    type: 'text',
  },
};

export const transferInput = {
  userId,
  businessId,
};
