import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useSubmitHandler } from 'shared-ui/lib/releox-engine/hooks/use-submit-handler/use-submit-handler';
import { AppFormScene } from 'shared-ui/lib/releox-engine/react/app-form-scene/AppFormScene';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { voucherEditForm } from '../../forms/voucher.form';
import { useUpdateVoucherMutation, useVoucherEditSceneQuery } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const VoucherEditScene = (): JSX.Element => {
  const { t } = useTranslation('VoucherEditScene');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const context = useGQLContext();

  const query = useVoucherEditSceneQuery({ variables: { id }, context });

  const [updateVoucher, { loading }] = useUpdateVoucherMutation({
    onCompleted: (data) => {
      if (data.updateVoucher) {
        toast.success(t('Common:saved'));
        navigate(routesConfig.VOUCHER.INDEX);
      }
    },
    context,
  });

  const handleSubmit = useSubmitHandler(updateVoucher, { id });

  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="voucher"
      title={t('title')}
      form={voucherEditForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={routesConfig.VOUCHER.INDEX}
    />
  );
};
