"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppIndexScenePagination = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var merge_classes_1 = require("../../utils/merge-classes/merge-classes");
var app_index_scene_pagination_config_1 = require("./app-index-scene-pagination-config");
var Pagination = function (_a) {
    var paginationItem = _a.paginationItem;
    var activeClasses = 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600';
    var defaultClasses = 'bg-white border-gray-300 text-gray-color hover:bg-gray-50';
    var extraClass = '';
    var text = paginationItem.text;
    if (paginationItem.type === 'first')
        text = (0, jsx_runtime_1.jsx)(app_index_scene_pagination_config_1.appIndexScenePaginationConfig.LeftIcon, { className: "h-5 w-5", "aria-hidden": "true" });
    if (paginationItem.type === 'last')
        text = (0, jsx_runtime_1.jsx)(app_index_scene_pagination_config_1.appIndexScenePaginationConfig.RightIcon, { className: "h-5 w-5", "aria-hidden": "true" });
    if (paginationItem.isFirst)
        extraClass = 'rounded-l-md';
    if (paginationItem.isLast)
        extraClass = 'rounded-r-md';
    return ((0, jsx_runtime_1.jsx)("button", { onClick: function () { return paginationItem.onClick(); }, className: (0, merge_classes_1.mergeClasses)(paginationItem.isCurrent ? activeClasses : defaultClasses, 'relative inline-flex items-center px-4 py-2 border text-sm font-medium', extraClass), children: text }));
};
var AppIndexScenePagination = function (_a) {
    var pagination = _a.pagination;
    var t = (0, react_i18next_1.useTranslation)('Pagination').t;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "flex items-center justify-between border-t border-gray-200 pt-4", children: [(0, jsx_runtime_1.jsxs)("div", { className: "flex-1 flex justify-between sm:hidden", children: [pagination.currentPageIndex > 0 ? ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "#", onClick: function () { return pagination.previousPage(); }, className: "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50", children: t('previous') })) : ((0, jsx_runtime_1.jsx)("span", {})), pagination.pageCount && pagination.currentPageIndex !== pagination.pageCount - 1 ? ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "#", onClick: function () { return pagination.nextPage(); }, className: "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50", children: t('next') })) : null] }), (0, jsx_runtime_1.jsxs)("div", { className: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between", children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("p", { className: "text-sm text-gray-700", children: [t('page'), " ", (0, jsx_runtime_1.jsx)("span", { className: "font-medium", children: pagination.currentPage }), ". ", t('result'), ' ', pagination.skip + 1, " - ", pagination.skip + pagination.perPage, " / ", pagination.total] }) }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("nav", { className: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px", "aria-label": "Pagination", children: pagination.pagination.map(function (paginationItem) { return ((0, jsx_runtime_1.jsx)(Pagination, { paginationItem: paginationItem }, paginationItem.text)); }) }) })] })] }));
};
exports.AppIndexScenePagination = AppIndexScenePagination;
