import { useRecoilValue } from 'recoil';
import { AppIndexScene } from 'shared-ui/lib/releox-engine/react/releox-app-index-scene/AppIndexScene';
import { vehicleColumn } from '../../columns/vehicle.column';
import { VehicleFilter } from '../../components/VehicleFilter';
import { useVehicleIndexSceneLazyQuery } from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';
import { vehicleFilterAtom } from '../../store/vehicle-filter.atom';

export const VehicleIndexScene = (): JSX.Element => {
  const vehicleFilter = useRecoilValue(vehicleFilterAtom);
  const context = useGQLContext();
  const query = useVehicleIndexSceneLazyQuery({
    context,
  });

  return (
    <div className="grid grid-cols-4 space-x-4">
      <div className="col-span-1">
        <VehicleFilter />
      </div>
      <div className="col-span-3">
        <AppIndexScene
          tNs="VehicleIndexScene"
          lazyQuery={query}
          columns={[
            vehicleColumn.id,
            vehicleColumn.business,
            vehicleColumn.user,
            vehicleColumn.registerNumber,
            vehicleColumn.isArchived,
            vehicleColumn.name,
            vehicleColumn.VIN,
            vehicleColumn.brand,
            vehicleColumn.model,
            vehicleColumn.token,
            vehicleColumn.linkToVehicle,
          ]}
          dataPath="aVehicles.list"
          totalCountPath="aVehicles.totalCount"
          where={vehicleFilter}
        />
      </div>
    </div>
  );
};
