"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPagination = void 0;
var getPagination = function (options) {
    var count = options.count, currentPageIndex = options.currentPageIndex, perPage = options.perPage, setCurrentPage = options.setCurrentPage;
    var paginationNumbers = [];
    for (var i = 0; i < Math.ceil(count / perPage); i += 1) {
        paginationNumbers.push(i);
    }
    var lastIndex = paginationNumbers.length - 1;
    var paginationItems = paginationNumbers
        .filter(function (i) { return i < currentPageIndex + 3 && i > currentPageIndex - 3; })
        .map(function (i) { return ({
        isFirst: false,
        isLast: false,
        type: 'page',
        text: "".concat(i + 1),
        isCurrent: currentPageIndex === i,
        page: i,
        onClick: function () { return setCurrentPage(i); },
    }); });
    if (currentPageIndex - 3 > -1) {
        paginationItems.unshift({
            isFirst: false,
            isLast: false,
            type: 'first',
            text: 'First',
            isCurrent: false,
            page: 1,
            onClick: function () { return setCurrentPage(0); },
        });
    }
    if (currentPageIndex + 2 < paginationNumbers[lastIndex]) {
        paginationItems.push({
            isFirst: false,
            isLast: false,
            type: 'last',
            text: 'Last',
            isCurrent: false,
            page: paginationNumbers[lastIndex],
            onClick: function () { return setCurrentPage(paginationNumbers[lastIndex]); },
        });
    }
    if (paginationItems.length) {
        paginationItems[0].isFirst = true;
        paginationItems[paginationItems.length - 1].isLast = true;
    }
    return paginationItems;
};
exports.getPagination = getPagination;
