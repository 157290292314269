"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = exports.buttonClassNames = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
exports.buttonClassNames = '';
var Button = function (props) {
    var t = (0, react_i18next_1.useTranslation)('Common').t;
    var type = props.type, children = props.children, loading = props.loading, className = props.className, onClick = props.onClick, style = props.style;
    var text = children;
    if (loading)
        text = t('loading');
    var size = "text-".concat(props.size || 'sm');
    // xs: px-2.5 py-1.5
    // sm: px-3 py-2
    var padding = 'px-3 py-2';
    if (props.size === 'xs') {
        padding = 'px-2.5 py-1.5';
    }
    return ((0, jsx_runtime_1.jsx)("button", { type: type, style: style, onClick: onClick, className: "inline-flex justify-center w-sm ".concat(padding, " shadow-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-gray-300 ").concat(size, " ").concat(className), disabled: loading, children: text }));
};
exports.Button = Button;
