"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Button_1 = require("./Button");
var SubmitButton = function (_a) {
    var loading = _a.loading, text = _a.text;
    return ((0, jsx_runtime_1.jsx)(Button_1.Button, { type: "submit", loading: loading, className: "float-right", children: text }));
};
exports.SubmitButton = SubmitButton;
