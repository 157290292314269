import { HomeIcon } from '@heroicons/react/outline';
import { List } from 'immutable';
import { useEffect, useState } from 'react';
import { routesConfig } from './routes.config';

const sidebarConfig: ListItem[] = [
  {
    name: 'voucher',
    href: routesConfig.VOUCHER.INDEX,
    icon: HomeIcon,
    current: false,
    exact: true,
  },
  {
    name: 'users',
    href: routesConfig.USER.INDEX,
    icon: HomeIcon,
    current: false,
    exact: false,
  },
  {
    name: 'vehicles',
    href: routesConfig.VEHICLE.INDEX,
    icon: HomeIcon,
    current: false,
    exact: false,
  },
  {
    name: 'adminOptions',
    href: routesConfig.ADMIN_OPTIONS,
    icon: HomeIcon,
    current: false,
    exact: true,
  },
];

type ListItem = {
  name: string;
  href: string;
  icon: typeof HomeIcon;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

export const useSideBarConfig = () => {
  const [list, setList] = useState<ListItem[]>([]);

  useEffect(() => {
    const localList = List<ListItem>(sidebarConfig);

    setList(localList.toArray());
  }, [setList]);

  return list;
};
