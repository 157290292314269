import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import * as Yup from 'yup';

const token: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'token',
    label: 'Vehicle:token',
    type: 'text',
  },
};

const isArchived: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.bool().required(),
  props: {
    name: 'isArchived',
    label: 'Vehicle:isArchived',
  },
};

export const vehicleInput = {
  token,
  isArchived,
};
