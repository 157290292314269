import { Link } from 'react-router-dom';
import { TableColumn } from 'shared-ui/lib/releox-engine/react/generic-table/TableColumn';
import { routesConfig } from '../config/routes.config';
import { UserIndexSceneQuery } from '../generated/graphql';

const id: TableColumn = {
  field: 'id',
  text: 'User:id',
};

const name: TableColumn = {
  field: 'name',
  text: 'User:name',
};

// Email
const email: TableColumn = {
  field: 'email',
  text: 'User:email',
};

// Type
const type: TableColumn = {
  field: 'type',
  text: 'User:type',
};

// partner.name
const isOwner: TableColumn = {
  field: 'isBusinessAccountOwner',
  text: 'User:isOwner',
  formatter: (data: UserIndexSceneQuery['aUsers']['list'][0]) => (
    <span>{data.isBusinessAccountOwner || data.isPartnerAccountOwner ? 'Kyllä' : '-'}</span>
  ),
};

// partner.name
const partnerName: TableColumn = {
  field: 'partner.name',
  text: 'User:partner',
  default: '-',
};

// business.name
const businessName: TableColumn = {
  field: 'business.name',
  text: 'User:business',
  default: '-',
};

const link: TableColumn = {
  field: 'id',
  text: 'General:link',
  formatter: (data: UserIndexSceneQuery['aUsers']['list'][0]) => (
    <Link
      to={routesConfig.USER.EDIT.replace(':id', data.id)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const userColumn = {
  name,
  id,
  email,
  type,
  isOwner,
  link,
  partnerName,
  businessName,
};
