import { createContext, useState } from 'react';

export const AuthContext = createContext<[string, (value: string) => void]>(['', () => ({})]);

type ChildrenProp = {
  children: JSX.Element;
};

export const AuthContextProvider = ({ children }: ChildrenProp) => {
  const state = useState<string>('');

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
