import { Route, Routes } from 'react-router';
import { DashboardLayout } from './components/DashboardLayout';
import { routesConfig } from './config/routes.config';
import { useSideBarConfig } from './config/sidebar.config';
import { AdminOptionsScene } from './scenes/admin-options/AdminOptionsScene';
import { UserEditScene } from './scenes/user/UserEditScene';
import { UserIndexScene } from './scenes/user/UserIndexScene';
import { VehicleEditScene } from './scenes/vehicle/VehicleEditScene';
import { VehicleIndexScene } from './scenes/vehicle/VehicleIndexScene';
import { VehicleTransferBusinessScene } from './scenes/vehicle/VehicleTransferBusinessScene';
import { VehicleTransferConsumerScene } from './scenes/vehicle/VehicleTransferConsumerScene';
import { VoucherCreateScene } from './scenes/voucher/VoucherCreateScene';
import { VoucherEditScene } from './scenes/voucher/VoucherEditScene';
import { VoucherIndexScene } from './scenes/voucher/VoucherIndexScene';

export const HomeRouter = () => {
  const nav = useSideBarConfig();
  return (
    <DashboardLayout user={{}} nav={nav} homeUrl={routesConfig.VOUCHER.INDEX}>
      <Routes>
        <Route path={routesConfig.VOUCHER.INDEX} element={<VoucherIndexScene />} />
        <Route path={routesConfig.VOUCHER.CREATE} element={<VoucherCreateScene />} />
        <Route path={routesConfig.VOUCHER.EDIT} element={<VoucherEditScene />} />
        <Route path={routesConfig.USER.INDEX} element={<UserIndexScene />} />
        <Route path={routesConfig.USER.EDIT} element={<UserEditScene />} />
        <Route path={routesConfig.ADMIN_OPTIONS} element={<AdminOptionsScene />} />
        <Route path={routesConfig.VEHICLE.INDEX} element={<VehicleIndexScene />} />
        <Route path={routesConfig.VEHICLE.EDIT} element={<VehicleEditScene />} />
        <Route
          path={routesConfig.VEHICLE.TRANSFER_BUSINESS}
          element={<VehicleTransferBusinessScene />}
        />
        <Route
          path={routesConfig.VEHICLE.TRANSFER_CONSUMER}
          element={<VehicleTransferConsumerScene />}
        />
      </Routes>
    </DashboardLayout>
  );
};
