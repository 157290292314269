import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInput } from '../inputs/user.input';

const editForm: FormInput[] = [userInput.name, userInput.email];

export const userEditForm = createForm(editForm);

export type UserFormBody = {
  name: string;
  email: string;
};
