import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { routesConfig } from '../config/routes.config';

type Props = {
  children: JSX.Element;
};

export const RequireGuest = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('admin-token');
    if (!token) setLoading(false);
    else navigate(routesConfig.VOUCHER.INDEX);
  }, [navigate]);

  if (loading) return <span />;

  return props.children;
};
