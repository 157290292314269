import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { AppFormScene } from 'shared-ui/lib/releox-engine/react/app-form-scene/AppFormScene';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { TransferBusinessFormBody, transferBusinessForm } from '../../forms/transfer-business.form';
import {
  usePreviewTransferBusinessLazyQuery,
  useTransferVehicleToBusinessMutation,
} from '../../generated/graphql';
import { useGQLContext } from '../../hooks/use-gql-context';

export const VehicleTransferBusinessScene = (): JSX.Element => {
  const { t } = useTranslation('VehicleTransferBusinessScene');
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const context = useGQLContext();

  const [updateVehicle, { loading }] = useTransferVehicleToBusinessMutation({
    onCompleted: (data) => {
      if (data.transferVehicleToBusiness) {
        toast.success(t('Common:saved'));
        navigate(routesConfig.VEHICLE.INDEX);
      }
    },
    context,
  });

  const [confirmTransfer] = usePreviewTransferBusinessLazyQuery({
    onCompleted: (data) => {
      if (data.aBusiness && data.aVehicle) {
        if (
          confirm(
            `Oletko varma että haluat siirtää ajoneuvon (${data.aVehicle.registerNumber}, ${data.aVehicle.VIN}) käyttäjälle ${data.aBusiness.name}?`
          )
        ) {
          updateVehicle({
            variables: {
              id,
              businessId: data.aBusiness.id,
            },
          });
        }
      }
    },
    context,
  });

  const handleSubmit = (body: TransferBusinessFormBody) => {
    confirmTransfer({
      variables: {
        id,
        businessId: body.businessId,
      },
    });
  };

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={transferBusinessForm}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={routesConfig.VEHICLE.EDIT.replace(':id', id)}
    />
  );
};
