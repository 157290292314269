"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIndexDefaultAdapter = void 0;
var useIndexDefaultAdapter = function (_a) {
    var orderBy = _a.orderBy, skip = _a.skip, take = _a.take, where = _a.where;
    return ({
        variables: {
            orderBy: [orderBy],
            skip: skip,
            take: take,
            where: where,
        },
    });
};
exports.useIndexDefaultAdapter = useIndexDefaultAdapter;
