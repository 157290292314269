"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_router_dom_1 = require("react-router-dom");
var LinkButton = function (_a) {
    var to = _a.to;
    var t = (0, react_i18next_1.useTranslation)('Common').t;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: to, className: "inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300", children: t('back') }));
};
exports.LinkButton = LinkButton;
