import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { vehicleFilterInput } from '../inputs/vehicle-filter.input';

const filter: FormInput[] = [
  vehicleFilterInput.id,
  vehicleFilterInput.VIN,
  vehicleFilterInput.registerNumber,
];

export const vehicleFilterForm = createForm(filter);

export type VehicleFilterFormBody = {
  id: string;
  registerNumber: string;
  VIN: string;
};
