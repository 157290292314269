"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appFormSceneConfig = void 0;
var AuthLoadingView_1 = require("../../../components/auth/AuthLoadingView");
var CardTitle_1 = require("../../../components/CardTitle");
var FormLayout_1 = require("../../../components/FormLayout");
exports.appFormSceneConfig = {
    Layout: FormLayout_1.FormLayout,
    Title: CardTitle_1.CardTitle,
    Loading: AuthLoadingView_1.AuthLoadingView,
};
