import { atom } from 'recoil';

export const vehicleFilterAtom = atom({
  key: 'vehicleFilterAtom',
  default: {
    id: '',
    registerNumber: '',
    VIN: '',
  },
});
